import { ButtonContent } from "../../global/boton/ButtonContent";

function HeroSection({ bgImg, title, texts }) {


  return (
    <div className="w-full h-screen relative overflow-hidden">
      

      <div className="w-full h-full absolute top-0 left-0 svgBoxRectanguloBottonRigth" style={{ background: `url("${bgImg}")`, backgroundPosition: "bottom center", backgroundSize: "cover" }}></div>
      <div className="z-10 relative w-4/5 mx-auto h-full flex flex-col justify-end items-center pb-20">
        <div className="w-full flex flex-col justify-center items-center">
          <h1 className="text-white text-center text-[35px] md:text-[60px]">
            {title}
          </h1>
          <p className="text-white text-center">
            {texts}
          </p>
        </div>

        <div className="w-full flex justify-center py-5">
          <ButtonContent btnStyle={'three'} />
        </div>

      </div>
    </div>


  )
}

export default HeroSection;
